export enum UserSettingName {
    CallsRingOnMobile = 'callsRingOnMobile',
    MenuFavorites = 'menuFavorites',
    SnoozePttUntil = 'snoozePttUntil',
    PttOnlyInFacility = 'pttOnlyInFacility',
    PttEducationalBanner = 'pttEducationalBanner',
    RadioScan = 'radioScan',
    AnnounceSender = 'announceSender',
    ReadIncomingMessages = 'readIncomingMessages',
    PlayDing = 'playDing',
    ShowIsSpeaking = 'showIsSpeaking',
    TextBanner = 'textBanner',
    PttBanner = 'pttBanner',
    ReadChannelName = 'readChannelName',
    ReadTranslations = 'readTranslations',
    AutoReply = 'autoReply',
    BannerTimeout = 'bannerTimeout',
    RespectIosMuteSwitch = 'respectIosMuteSwitch',
    DisableChannelsTouchscreen = 'disableChannelsTouchScreen',
    MusicStation = 'musicStation',
    WaltMusicTipShownCount = 'waltMusicTipShownCount',
    SiteSurveyTutorialShownCount = 'siteSurveyTutorialShownCount',
    QueueIncomingMessages = 'queueIncomingMessages',
    PrioritizeQueuedMessagesByChannel = 'queuedMessagesPrioritizeByChannel',
    FavoriteChannels = 'favoriteChannels',
}
export interface FavoriteChannelSetting {
    channelId: string;
}

export interface UserSettingFavoriteChannels extends BaseUserSetting {
    id: UserSettingId<UserSettingName.FavoriteChannels>;
    settings: FavoriteChannelSetting[];
}

export interface MenuFavoritesSetting {
    name: string;
    position: number;
}
export interface AutoReplySetting {
    enabled: boolean;
    message?: string;
}

export interface MusicStation {
    id: number;
    name: string;
    imageUrl: string;
    streamUrl: string;
    description?: string;
}

export type UserSettingId<T = UserSettingName> = { userId: string, name: T };
export type UserSettingValue = MenuFavoritesSetting[] | AutoReplySetting | Date | boolean | number | MusicStation | FavoriteChannelSetting[];

export interface BaseUserSetting {
    id: UserSettingId;
    settings: UserSettingValue;
}

export interface UserSettingCallsRingOnMobile extends BaseUserSetting {
    id: UserSettingId<UserSettingName.CallsRingOnMobile>;
    settings: boolean;
}

export interface UserSettingMenu extends BaseUserSetting {
    id: UserSettingId<UserSettingName.MenuFavorites>;
    settings: MenuFavoritesSetting[];
}

export interface UserSettingAutoReply extends BaseUserSetting {
    id: UserSettingId<UserSettingName.AutoReply>;
    settings: AutoReplySetting;
}

export interface UserSettingPttSnooze extends BaseUserSetting {
    id: UserSettingId<UserSettingName.SnoozePttUntil>;
    settings: Date;
}

export interface UserSettingPttOnlyInFacility extends BaseUserSetting {
    id: UserSettingId<UserSettingName.PttOnlyInFacility>;
    settings: boolean;
}

export interface UserSettingPttEducationalBanner extends BaseUserSetting {
    id: UserSettingId<UserSettingName.PttEducationalBanner>;
    settings: number;
}

export interface UserSettingRadioScan extends BaseUserSetting {
    id: UserSettingId<UserSettingName.RadioScan>;
    settings: boolean;
}

export interface UserSettingAnnounceSender extends BaseUserSetting {
    id: UserSettingId<UserSettingName.AnnounceSender>;
    settings: boolean;
}

export interface UserSettingReadIncomingMessages extends BaseUserSetting {
    id: UserSettingId<UserSettingName.ReadIncomingMessages>;
    settings: boolean;
}

export interface UserSettingPlayDing extends BaseUserSetting {
    id: UserSettingId<UserSettingName.PlayDing>;
    settings: boolean;
}

export interface UserSettingShowIsSpeaking extends BaseUserSetting {
    id: UserSettingId<UserSettingName.ShowIsSpeaking>;
    settings: boolean;
}

export interface UserSettingTextBanner extends BaseUserSetting {
    id: UserSettingId<UserSettingName.TextBanner>;
    settings: boolean;
}

export interface UserSettingPttBanner extends BaseUserSetting {
    id: UserSettingId<UserSettingName.PttBanner>;
    settings: boolean;
}

export interface UserSettingReadChannelName extends BaseUserSetting {
    id: UserSettingId<UserSettingName.ReadChannelName>;
    settings: boolean;
}

export interface UserSettingReadTranslation extends BaseUserSetting {
    id: UserSettingId<UserSettingName.ReadTranslations>;
    settings: boolean;
}

export interface UserSettingBannerTimeout extends BaseUserSetting {
    id: UserSettingId<UserSettingName.BannerTimeout>;
    settings: number;
}

export interface UserSettingRespectIosMuteSwitch extends BaseUserSetting {
    id: UserSettingId<UserSettingName.RespectIosMuteSwitch>;
    settings: boolean;
}

export interface UserSettingMusicStation extends BaseUserSetting {
    id: UserSettingId<UserSettingName.MusicStation>;
    settings: MusicStation;
}

export interface UserSettingDisableChannelsTouchscreen extends BaseUserSetting {
    id: UserSettingId<UserSettingName.DisableChannelsTouchscreen>;
    settings: boolean;
}

export interface UserSettingWaltMusicTipShownCount extends BaseUserSetting {
    id: UserSettingId<UserSettingName.WaltMusicTipShownCount>;
    settings: number;
}

export interface UserSettingSiteSurveyTutorialShownCount extends BaseUserSetting {
    id: UserSettingId<UserSettingName.SiteSurveyTutorialShownCount>;
    settings: number;
}

export type UserSetting =
    | UserSettingCallsRingOnMobile
    | UserSettingMenu
    | UserSettingPttSnooze
    | UserSettingPttOnlyInFacility
    | UserSettingPttEducationalBanner
    | UserSettingRadioScan
    | UserSettingAnnounceSender
    | UserSettingReadIncomingMessages
    | UserSettingPlayDing
    | UserSettingShowIsSpeaking
    | UserSettingTextBanner
    | UserSettingPttBanner
    | UserSettingReadChannelName
    | UserSettingReadTranslation
    | UserSettingAutoReply
    | UserSettingBannerTimeout
    | UserSettingRespectIosMuteSwitch
    | UserSettingMusicStation
    | UserSettingDisableChannelsTouchscreen
    | UserSettingWaltMusicTipShownCount
    | UserSettingSiteSurveyTutorialShownCount
    | UserSettingFavoriteChannels;

