import { Injectable, NgZone } from '@angular/core';
import { MqttQos } from '@weavix/mqtt';

import { AudioData } from '@weavix/models/src/media/media';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';

import { environment } from 'environments/environment';
import { v4 as uuid } from 'uuid';
import { Topic } from '@weavix/models/src/topic/topic';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { sleep } from 'weavix-shared/utils/sleep';
import { LocalDeviceUtility } from '@weavix/domain/src/utils/local-device-utility';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AnalyticsService, StAction, StObject } from './analytics.service';
import { Channel } from 'models-mobx/channels-store/channel';
import { myUser } from 'models-mobx/users-store/users-store';
import { StopWatch } from '@weavix/utils/src/stopwatch';
import { myCompany } from 'models-mobx/companies-store/companies-store';

export const MAX_PTT_TIME = 300000;
const PTT_TIMEOUT = 15000;

@Injectable({ providedIn: 'root' })
export class PttService {
    private assets: { [key: string]: HTMLAudioElement } = {};

    playing: boolean;
    recording: boolean;

    private enders: Array<{ func: () => Promise<void> }> = [] as any;
    private context: AudioContext;
    private destination: MediaStreamAudioDestinationNode;
    private contextAudio: HTMLAudioElement;

    constructor(
        private pubSubService: PubSubService,
        private profileService: ProfileService,
        private zone: NgZone,
        private deviceService: DeviceDetectorService,
    ) {
    }

    prepare() {
        const prepare = () => {
            this.preplayAudio();
            document.removeEventListener('mousedown', prepare);
            document.removeEventListener('touchstart', prepare);
            document.removeEventListener('keydown', prepare);
        };
        document.addEventListener('mousedown', prepare);
        document.addEventListener('touchstart', prepare);
        document.addEventListener('keydown', prepare);
    }

    async subscribeAudio(component) {
        const user = await this.profileService.getUserProfile(component);
        return this.pubSubService.subscribe<AudioData>(component, Topic.UserAudio, [user.id]);
    }

    async startPtt(component, channel: Channel, onTimeout: () => void) {
        return await this.startPttInternal(component, {
            channelId: channel.id,
            accountId: channel.accountId,
            date: new Date().toISOString(),
            fromTeams: environment.teamsApp,
        }, channel, onTimeout);
    }

    async startPttWilma(component, wilmaId: string, cameraId: string, onTimeout) {
        return await this.startPttInternal(component, { wilma: { id: wilmaId, cameraId } }, null, onTimeout);
    }

    async startPttInternal(component, data: Partial<AudioData>, channel: Channel = null, onTimeout: () => void) {
        if (!this.context) this.preplayAudio();
        if (this.playing) {
            this.playAsset('busy-tone');
            return false;
        }

        const buttonDelayStopWatch = new StopWatch().start();
        let started = false;
        let startResolve;
        const startPromise = new Promise(resolve => startResolve = resolve);
        let closed = false;
        const pttId = uuid();

        const stopTracks = () => {
            stream.getTracks().forEach(track => track.stop());
        };

        const close = async (wait: boolean) => {
            if (wait) await sleep(500);
            this.recording = false;

            source.disconnect();
            processor.disconnect();

            worker.postMessage({ command: 'record', id: pttId });

            stopTracks();

            await sleep(50);
            this.sendAudio(this, data.accountId, pttId);

            this.playAsset('nextel-beep-stop', { timeout: 1000 });

            AnalyticsService.track(StObject.Ptt, StAction.Pressed, this.constructor.name, {
                duration: new Date().getTime() - new Date(data.date).getTime(),
                channelId: data.channelId,
                accountId: data.accountId,
                destinationLanguages: channel.channelLanguages,
                memberCount: channel.users.length,
                channelType: channel.type,
                sourceLanguage: myUser().locale,
            });
        };

        this.enders.push({ func: async () => {
            closed = true;
            const wait = started;
            await startPromise;
            await close(wait);
        } });

        // windows sucks and cannot handle 48000
        const sampleRate = this.deviceService.getDeviceInfo().os.toLowerCase() === 'windows' ? 16000 : 48000;

        const device = await LocalDeviceUtility.getDefaultAudioInputDevice();
        // This handles a case where you can get a permission prompt but the keyup event is not fired to cancel the ptt.
        // This will detect that getting the stream is taking longer than it should (for the mic permission prompt) and cancel
        const stream = await Promise.race([
            LocalDeviceUtility.getDeviceStream(device.deviceId || true, false, { audio: { sampleRate, channelCount: 1 } }),
            sleep(2000).then(() => null), // Safari can take >1s to get the stream on the first call
        ]);
        if (!stream) throw new Error('No permission');

        if (closed) {
            stopTracks();
            return false;
        }

        this.recording = true;
        setTimeout(() => !closed && onTimeout(), MAX_PTT_TIME);

        let context: AudioContext = null;
        let source: MediaStreamAudioSourceNode = null;
        let worker: Worker = null;
        let processor: ScriptProcessorNode;

        this.zone.runOutsideAngular(() => {
            worker = new Worker('/assets/js/audio/audio-worker.js');
            worker.onmessage = event => {
                switch (event.data.command) {
                    case 'data':
                        this.sendAudio(this, data.accountId, pttId, event.data.blob);
                        break;
                }
            };

            try {
                context = new (window.AudioContext || window['webkitAudioContext'])({ sampleRate });
                source = context.createMediaStreamSource(stream);
            } catch (e) {
                context = new (window.AudioContext || window['webkitAudioContext'])();
                source = context.createMediaStreamSource(stream);
            }
        });

        const frequency = context.sampleRate;
        const senderAckStopWatch = new StopWatch().start();
        const firstRoundTripPromise = this.prepareAudio(this, data.accountId, {
            id: pttId,
            frequency,
            ...data,
        });

        const onTelemetry = () => {
            if (buttonDelayStopWatch.isRunning() || senderAckStopWatch.isRunning()) return;
            this.sendTelemetry('sent', {
                id: pttId,
                accountId: channel?.accountId,
                channelId: channel?.id,
            }, {
                senderAckTime: senderAckStopWatch.elapsed(),
                buttonDelay: buttonDelayStopWatch.elapsed(),
            });
        };

        firstRoundTripPromise.then(() => {
            senderAckStopWatch.stop();
            onTelemetry();
        });

        await this.playAsset('nextel-beep-start', { timeout: 1000 });
        await sleep(50);
        started = true;

        buttonDelayStopWatch.stop();
        onTelemetry();

        this.zone.runOutsideAngular(() => {
            processor = (context.createScriptProcessor || context['createJavaScriptNode']).call(context, 8192, 1, 1);
            source.connect(processor);
            processor.connect(context.destination);

            processor.onaudioprocess = function (event) {
                if (!started) return;
                const buffer = [event.inputBuffer.getChannelData(0)];
                worker.postMessage({ command: 'record', id: pttId, buffer, sampleRate: event.inputBuffer.sampleRate || frequency, channels: 1 });
            };
        });

        startResolve();
        return true;
    }

    async endPtt() {
        let nextEnder = this.enders.shift();
        while (nextEnder) {
            await Promise.race([nextEnder.func(), sleep(600)]);
            nextEnder = this.enders.shift();
        }
    }

    // Needed for safari
    preplayAudio() {
        try {
            if (!this.context) {
                this.context = new (window.AudioContext || window['webkitAudioContext'])({ sampleRate: 48000 });
                this.contextAudio = new Audio();
                this.destination = this.context.createMediaStreamDestination();
                this.contextAudio.srcObject = this.destination.stream;
            }
            this.contextAudio.play();

            const buffer = this.context.createBuffer(1, 1, 22050);
            const source = this.context.createBufferSource();
            source.buffer = buffer;
            source.connect(this.destination);
            source.start(0);
        } catch (e) {
            console.error(e);
        }

        this.testAudio('busy-tone');
        this.testAudio('electronic-buzz-buzz');
        this.testAudio('nextel-beep-start');
        this.testAudio('nextel-beep-stop');
    }

    playStream(stream: MediaStream) {
        if (!this.context) this.preplayAudio();
        this.contextAudio.srcObject = stream;
        this.contextAudio.play();
    }

    async prepareAudio(component, accountId: string, data: AudioData) {
        const user = await this.profileService.getUserProfile(component);
        await this.pubSubService.publish(accountId ? Topic.AccountAudioStart : Topic.UserAudioStart, [accountId ?? user.id, data.id], {
            locale: user.locale,
            ...data,
        });
    }

    async sendAudio(component, accountId: string, id: string, data?) {
        const user = await this.profileService.getUserProfile(component);
        const qos = data ? MqttQos.none : MqttQos.atLeastOnce;
        await this.pubSubService.publish(accountId ? Topic.AccountAudioSend : Topic.UserAudioSend, [accountId ?? user.id, id], data || new Uint8Array(0), qos);
    }

    async playAudio(component, accountId: string, audioData: AudioData) {
        const { id } = audioData;
        const user = await this.profileService.getUserProfile(component);
        return await this.playAudioInternal(component, accountId ? Topic.AccountAudioReceive : Topic.UserAudioReceive, [accountId ?? user.id, id], audioData, false);
    }

    async playAudioInternal(component, topic, args, audioData: AudioData, live: boolean) {
        if (!this.context) this.preplayAudio();

        if (this.recording || this.playing) {
            const reason = this.playing ? 'ptt-receiving' : 'ptt-sending';
            console.log(`skipPtt ${reason}`);
            this.sendTelemetry('skipped', audioData, { reason });
            return;
        }

        this.playing = true;
        this.sendTelemetry('received', audioData, { isPlayback: !live, isRestart: false });
        let subs;
        const channels = audioData?.channels ?? 1;
        try {
            let closed = false;
            const close = async () => {
                if (closed) return;
                closed = true;

                if (subs) subs.unsubscribe();
                if (!live) await sleep(Math.max(1, offset - this.context.getOutputTimestamp().contextTime));
                this.playing = false;
            };
            setTimeout(close, MAX_PTT_TIME);
            let timeout = setTimeout(close, PTT_TIMEOUT);

            const outputDevice = await LocalDeviceUtility.getDefaultAudioOutputDevice();
            if (outputDevice?.deviceId) (this.contextAudio as any).setSinkId?.(outputDevice.deviceId);

            this.destination = this.context.createMediaStreamDestination();
            this.contextAudio.srcObject = this.destination.stream;
            this.contextAudio.play();

            let offset = 0;
            let pending = [];
            let pendingLength = 0;
            let addedLength = 0;
            let pendingFrames = 0;
            // eslint-disable-next-line complexity
            subs = this.pubSubService.subscribe<Buffer>(component, topic, args, false, 0, true).subscribe(async data => {
                clearTimeout(timeout);
                timeout = setTimeout(close, PTT_TIMEOUT);

                const payload = data.payload;
                if (!payload.length) close();

                pending.push(payload);
                pendingLength += payload.length;
                addedLength += payload.length;
                if (!payload.length && pendingLength >= 2048 && addedLength > 0 || addedLength >= 4096) {
                    const buf = new Uint8Array(pendingLength);
                    for (let i = 0, j = 0; i < pendingLength && j < pending.length; j++) {
                        buf.set(pending[j], i);
                        i += pending[j].length;
                    }

                    let buffer: AudioBuffer;
                    if (audioData?.pcm) {
                        const length = buf.length / channels / 2;
                        buffer = this.context.createBuffer(channels, length, audioData.frequency);
                        for (let j = 0; j < channels; j++) {
                            const channel = buffer.getChannelData(j);
                            for (let i = 0; i < length; i++) {
                                // eslint-disable-next-line no-bitwise
                                const ui = buf[(i * channels + j) * 2] + (buf[(i * channels + j) * 2 + 1] << 8);
                                channel[i] = (ui >= 32768 ? ui - 65536 : ui) / 32768;
                            }
                        }
                        pending = [];
                        pendingLength = 0;
                        addedLength = 0;
                    } else {
                        const indexes = [];
                        for (let i = 0; i < buf.length - 6; i++) {
                            // eslint-disable-next-line no-bitwise
                            if (buf[i] === 0xFF && (buf[i + 1] & 0xF6) === 0xF0) {
                                indexes.push(i);
                                // eslint-disable-next-line no-bitwise
                                const frameLength = ((buf[i + 3] & 3) << 11) + (buf[i + 4] << 3) + (buf[i + 5] >> 5) - 7;
                                i += frameLength - 1;
                            }
                        }
                        const previousFrames = pendingFrames;
                        const postFrames = 1;
                        pendingFrames = Math.min(indexes.length, 4) - postFrames;
                        pending = [buf.slice(indexes[indexes.length - pendingFrames - postFrames])];
                        pendingLength = pending[0].length;
                        addedLength = 0;

                        buffer = await new Promise(resolve => {
                            this.context.decodeAudioData(buf.buffer, resolve, err => console.error(err, indexes));
                        });
                        const samplesPerFrame = Math.round(buffer.length / indexes.length);
                        if (buffer.length > samplesPerFrame * (previousFrames + postFrames)) {
                            const newBuffer = this.context.createBuffer(buffer.numberOfChannels, buffer.length - samplesPerFrame * (previousFrames + postFrames), buffer.sampleRate);
                            for (let j = 0; j < buffer.numberOfChannels; j++) {
                                const channel = buffer.getChannelData(j);
                                const newChannel = newBuffer.getChannelData(j);
                                newChannel.set(channel.slice(samplesPerFrame * previousFrames, buffer.length - postFrames * samplesPerFrame));
                            }
                            buffer = newBuffer;
                        } else {
                            return;
                        }
                    }

                    const currentTime = this.context.getOutputTimestamp().contextTime;
                    if (live && (offset < currentTime || offset > currentTime + 1.5)) console.warn(`Offset ${offset} current ${currentTime}`);
                    offset = Math.max(offset, currentTime + 0.5);
                    if (!live || offset < currentTime + 1.5) {
                        const source = this.context.createBufferSource();
                        source.buffer = buffer;
                        source.connect(this.destination);
                        source.start(offset);
                        offset += buffer.duration;
                    }
                }
            });

            return close;
        } catch (e) {
            if (live) throw e;

            console.error(e);
            this.contextAudio.src = `${environment.is360Api}/a/${args[0]}/media/audio/${args[1]}/stream.aac`;
            this.contextAudio.play();
        }
    }

    async playAsset(file: string, options?) {
        return new Promise(resolve => {
            try {
                const asset = this.getAudio(file);
                asset.currentTime = 0;
                asset.muted = false;
                asset.loop = options?.loop ?? false;
                asset.play();
                asset.onended = resolve;
            } catch (e) {
                console.error(e);
            }
            if (options?.timeout) setTimeout(resolve, options?.timeout);
        });
    }

    isPlaying(file: string) {
        const asset = this.getAudio(file);
        return asset['isPlaying'];
    }

    async stopAsset(file: string) {
        const asset = this.getAudio(file);
        asset.pause();
    }

    testAudio(file: string) {
        try {
            const asset = this.getAudio(file);
            asset.muted = true;
            asset.currentTime = 0;
            asset.play();
        } catch (e) {
            // Ignore
        }
    }

    getAudio(file: string) {
        let asset = this.assets[file];
        if (!asset) {
            const src = `/assets/sounds/${file}.wav?ngsw-bypass=true`;
            asset = new Audio();
            asset.src = src;
            this.assets[file] = asset;
        }
        return asset;
    }

    private async sendTelemetry(type: 'received' | 'sent' | 'sent-jitter' | 'jitter' | 'skipped' | 'buzzed', payload: AudioData, props: any) {
        try {
            const myId = myUser().id;
            await this.pubSubService.publish(Topic.PttTelemetry, [myId], {
                type,
                id: payload.id,
                channelId: payload.channelId,
                accountId: payload.accountId ?? myCompany(),
                isAdcMessage: !!payload.accountId,
                personId: payload.personId ?? myId,
                date: new Date().toISOString(),
                device: 'web',
                build: environment.version,
                ...props,
            });
        } catch (e) {
            console.warn(`Failed to publish PTT telemetry ${e?.message}`);
        }
    }
}
